import React from "react";

const ToolsUsed = () => {
  const tools = [
    { name: "Eclipse", iconPath: require("../images/icons/eclipse-icon.jpeg") },
    { name: "VS Code", iconPath: require("../images/icons/vscode-icon.jpeg") },
    { name: "Sublime", iconPath: require("../images/icons/sublime-icon.jpeg") },
    { name: "SQL", iconPath: require("../images/icons/sql-icon.png") },
    { name: "Toad", iconPath: require("../images/icons/toad-icon.png") },
    { name: "Git", iconPath: require("../images/icons/git-icon.png") },
    { name: "Jenkins", iconPath: require("../images/icons/jenkins-icon.png") },
    { name: "SVN", iconPath: require("../images/icons/svn-icon.png") },
    {
      name: "Source Tree",
      iconPath: require("../images/icons/sourcetree-icon.jpeg"),
    },
    { name: "JIRA", iconPath: require("../images/icons/jira-icon.jpeg") },
    { name: "RTC", iconPath: require("../images/icons/rtc-icon.jpeg") },
  ];

  return (
    <div className="tools-used-cls">
      <h2>Tools Used</h2>
      <div className="tools-list">
        {tools.map((tool, index) => (
          <div key={index} className="tool-item">
            <img src={tool.iconPath} alt={tool.name} className="tool-icon" />
            <span className="tooltip">{tool.name}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ToolsUsed;
